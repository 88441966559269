import * as React from "react";
import Button, { ButtonVariant } from "../../components/Button";
import Layout from "../../components/Layout";
import { useLayout } from "../../components/Layout/layout-context";
import MicroSection from "../../components/MicroSection";
import Title, { TitleVariant } from "../../components/Title";

const ExperienceClient = () => {
  const [setShowModal] = useLayout();

  return (
    <Layout
      title="Nos formations en UX Design"
      metaTitle="Devenez UX Designer"
      description="Découvrez les fondamentaux de l’UX, fédérez vos équipes grâce à des ateliers, faites de la recherche utilisateur avec notre formation d'ux designer"
    >
      <Title variant={TitleVariant.h2} text="Programme" className="mt-main" />
      <Title
        variant={TitleVariant.h3}
        text="Faites de vos salariés des futurs UX designers !"
        className="mt-secondary"
      />

      <p className="mt-secondary">
        Faites-les monter en compétences en UX design sur un de vos propres
        projets
      </p>

      <MicroSection title="🍩 Introduction à l'UX design">
        Profitez de ce moment pour apprendre les rudiments de ce métier. Vous
        serez directement à même de mettre en place des techniques centrées
        utilisateur à forte valeur ajoutée pour votre entreprise. Cet
        apprentissage peut se faire en parallèle d'un projet réel de votre
        entreprise ou d'un projet fictif.
      </MicroSection>
      <MicroSection
        title="🍩 Effectuer vos propres recherches utilisateur
      "
      >
        Soyez autonome dans la réalisation de vos entretiens utilisateurs et
        dans la création de questionnaires pour comprendre les besoins et les
        freins de vos utilisateurs.
      </MicroSection>
      <MicroSection
        title="🍩 Animer des ateliers pour profiter de la créativité de votre équipe et de vos utilisateurs
      "
      >
        Les ateliers de co-conception sont idéaux pour offrir une vision à toute
        votre équipe et relever les challenges liés à votre site.
      </MicroSection>
      <MicroSection
        title="🍩 Concevoir un prototype pour anticiper des évolutions
      "
      >
        La conception d'un prototype vous fera gagner énormément de temps et
        d'argent. Fini les aller-retours avec vos développeurs pour modifier un
        élément, tout cela se fera en amont, afin de livrer une maquette idéale
        à développer.
      </MicroSection>
      <MicroSection
        title="🍩 Recueillir l'avis d'utilisateur sur vos interfaces
      "
      >
        Que ce soit pour vos maquettes ou votre site existant, il est essentiel
        d'évoluer en cohérence avec les avis de vos utilisateurs. Ces tests sont
        là pour corriger 95% des problèmes d'utilisabilité et vous permettre de
        mettre en ligne des pages attractives.
      </MicroSection>

      <p className="mt-secondary">
        Contactez-nous en précisant la formation qui vous intéresse !
      </p>
      <div className="flex flex-col items-center justify-center mt-secondary lg:flex-row">
        <Button
          variant={ButtonVariant.primary}
          text="Former mon équipe"
          className=""
          onClick={() => setShowModal(true)}
        />
        <Button
          variant={ButtonVariant.secondary}
          text="Retour aux formations"
          className="mt-6 lg:mt-0 lg:ml-10"
          to="/formations"
        />
      </div>
    </Layout>
  );
};

export default ExperienceClient;
